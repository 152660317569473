import React from 'react';
import { oneOf } from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import { Avatar, NamedLink, UserDisplayName, BookingInfoMaybe } from 'components/index';
import { propTypes } from 'util/types';
import {
  txIsRequested,
  txIsPriceOffered,
  txIsEnquired,
  txIsWorkStarted,
} from 'util/transaction';
import { FormattedMessage, intlShape } from 'util/reactIntl';
import { createSlug, stringify } from 'util/urlHelpers';

// import css from 'containers/InboxPage/InboxPage.css';
import css from './InboxItem.css';
import { formatMoney } from 'util/currency';
import { ensurePrice } from 'util/data';
const _ = require('lodash');

const createListingLink = (listing, otherUser, searchParams = {}, className = '') => {
  const listingId = listing.id && listing.id.uuid;
  const label = listing.attributes.title;
  const listingDeleted = listing.attributes.deleted;

  if (!listingDeleted) {
    const params = { id: listingId, slug: createSlug(label) };
    const to = { search: stringify(searchParams) };
    return (
      <NamedLink className={className} name="ListingPage" params={params} to={to}>
        <Avatar user={otherUser} disableProfileLink />
      </NamedLink>
    );
  } else {
    return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
  }
};

const removeUnit = inputText => {
  return _.trimStart(inputText, 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
};

const InboxItem = props => {
  const { unitType, type, tx, intl, stateData, currentUser } = props;
  const workflowStatus = tx.attributes.metadata?.workflowStatus;
  
  const { customer, provider, listing } = tx;
  const isOrder = type === 'order';
  const isAdmin = type === 'admin';

  const otherUser = isOrder || isAdmin ? provider : customer;

  const tripName =
    tx.attributes.protectedData && tx.attributes.protectedData?.tripDetails?.tripName;
  const tripLocation =
    tx.attributes.protectedData?.tripDetails?.tripLocation &&
    tx.attributes.protectedData?.tripDetails?.tripLocation.search;
  const targetStartDate =
    tx.attributes.protectedData && tx.attributes.protectedData?.tripDetails?.targetStartDate;
  const minBudget =
    tx.attributes.protectedData &&
    ensurePrice(tx.attributes.protectedData?.tripDetails?.estimatedMinBudget);
  const maxBudget =
    tx.attributes.protectedData &&
    ensurePrice(tx.attributes.protectedData?.tripDetails?.estimatedMaxBudget);
  const minBudgetChip =
    minBudget?.currency && minBudget?.amount && formatMoney(intl, minBudget, 0, 2);
  const maxBudgetChip =
    maxBudget?.currency && maxBudget?.amount && formatMoney(intl, maxBudget, 0, 2);
  const maxBudgetChipInUse = removeUnit(maxBudgetChip);
  const totalPrice = ensurePrice(tx.attributes.payinTotal);

  const otherUserDisplayName = (
    <UserDisplayName user={!isAdmin ? otherUser : customer} intl={intl} />
  );
  const isOtherUserBanned = otherUser.attributes.banned;

  const isSaleNotification = !isOrder && txIsRequested(tx);
  const rowNotificationDot =
    isSaleNotification ? <div className={css.notificationDot} /> : null;

  const linkClasses = classNames(css.itemLink, {
    [css.bannedUserLink]: isOtherUserBanned,
  });

  const listingLink = listing ? createListingLink(listing, otherUser) : null;
  return (
    <div className={css.item}>
      <div className={css.itemAvatar}>
        {isOrder && listing ? listingLink : <Avatar className={css.avatarUser} user={otherUser} />}
      </div>
      <NamedLink
        className={linkClasses}
        name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
        params={{ id: tx.id.uuid }}
        disabled={isAdmin}
      >
        <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
        <div className={css.itemInfo}>
          <div>
            {isAdmin && (
              <div className={css.itemProviderName}>
                <UserDisplayName user={provider} intl={intl} />
              </div>
            )}
            <div className={css.itemUsername}>
              <div className={css.clientBookListingWrapper}>
                <span
                  className={isAdmin ? css.userDisplayNameAdminWrapper : css.userDisplayNameWrapper}
                >
                  {otherUserDisplayName} - {tripName}
                </span>
                <span className={css.listingNameWrapper}>
                  <div
                    className={classNames(css.chip, css.mobileMaybe)}
                  >{`Budget: ${minBudgetChip} - ${maxBudgetChipInUse}`}</div>
                  <div className={classNames(css.chip, css.mobileMaybe)}>{tripLocation}</div>
                  <div className={classNames(css.chip, css.mobileMaybe)}>
                    {moment(new Date(targetStartDate)).format('ddd Do MMM')}
                  </div>
                  <div className={classNames(css.chip, css.mobileMaybe)}>
                    {formatMoney(intl, totalPrice, 0, 2)}
                  </div>
                </span>
              </div>
            </div>
            <div className={css.stateDetail}>
              <BookingInfoMaybe
                bookingClassName={stateData.bookingClassName}
                intl={intl}
                isOrder={isOrder}
                tx={tx}
              />
            </div>
          </div>
          <div
            className={classNames(
              css.stateName,
              {
                [css.stateDelivered]: stateData.state === 'Delivered / Collaborate',
                [css.stateAccepted]: stateData.state === 'Accepted',
                [css.stateCancelled]: stateData.state === 'Cancelled',
                [css.stateSucces]: stateData.state === 'Completed',
              },
              stateData.stateClassName
            )}
          >
            <div className={css.completeLabelWrapper}>
              {/* {stateData.state === "Completed" && (<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <circle cx="5.89023" cy="5.89023" r="5.23576" stroke="#31D296" strokeWidth="1.30894" />
                <path d="M3.92725 6.03641L5.23619 7.52715L8.50854 4.58203" stroke="#31D296" strokeWidth="1.30894" />
              </svg>)} */}
              {stateData.state}
            </div>
            {!isOrder &&
              (txIsEnquired(tx) || (txIsWorkStarted(tx) && workflowStatus !== 'delivered')) && <div className={css.actionRequiredMark}>Action Required</div>}
            {isOrder && (txIsPriceOffered(tx)) && (
              <div className={css.actionRequiredMark}>Action Required</div>
            )}
          </div>
        </div>
      </NamedLink>
    </div>
  );
};

InboxItem.propTypes = {
  type: oneOf(['order', 'sale', 'admin']).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default InboxItem;
