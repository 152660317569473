import React, { Component, createRef } from 'react';
import { bool, func, instanceOf, object, oneOfType, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { types as sdkTypes } from 'util/sdkLoader';
import { pathByRouteName, findRouteByRouteName, createResourceLocatorString } from '../../util/routes';
import {
  propTypes,
  DATE_TYPE_DATE,
} from '../../util/types';
import {
  ensureListing,
  ensureCurrentUser,
  ensureUser,
  ensureTransaction,
  ensureBooking,
  ensureStripeCustomer,
  ensurePaymentMethodCard,
  addonLabelIntoCode,
} from '../../util/data';
import { timeOfDayFromLocalToTimeZone, minutesBetween } from '../../util/dates';
import { createSlug } from '../../util/urlHelpers';
import {
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateListingNotFoundError,
  isTransactionInitiateMissingStripeAccountError,
  isTransactionInitiateBookingTimeNotAvailableError,
  isTransactionChargeDisabledError,
  isTransactionZeroPaymentError,
  transactionInitiateOrderStripeErrors,
  isTransactionInitiateOrderErrorTemporary,
  isTransactionCardError,
} from '../../util/errors';
import { convertMoneyToNumber } from '../../util/currency';
import {
  TRANSITION_ENQUIRE,
  txIsPaymentPending,
  txIsPaymentExpired,
  txHasPassedPaymentPending,
} from '../../util/transaction';
import {
  Button,
  OrderBreakdown,
  NamedLink,
  NamedRedirect,
  Page,
  ResponsiveImage,
  TripSummary,
  UserDisplayName,
  TaxJarInfo
} from '../../components';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { confirmCardPayment, retrievePaymentIntent } from '../../ducks/stripe.duck';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import {
  initiateOrder,
  setInitialValues,
  speculateTransaction,
  stripeCustomer,
  confirmPayment,
  addEventToCalendar,
  sendMessage,
  hitPay,
  addChargeToOrder,
  clearInitiateOrderError,
} from './CheckoutPage.duck';
import { PAYMENT_TYPES } from 'util/constants';
import { storeData, storedData, clearData } from './CheckoutPageSessionHelpers';
import css from './CheckoutPage.css';
import { LINE_ITEM_CARD_SERVICE_FEE } from 'util/types';
import ChargeCustomerForm from './ChargeCustomerForm/ChargeCustomerForm';
import WarningIcon from '../../assets/warning-error-svgrepo-com.svg';
import { trackAnalyticsEventWithContext } from 'ducks/analytics.duck';

const { UUID, Money } = sdkTypes;
const STORAGE_KEY = 'CheckoutPage';
const isStripeTestMode = !!(
  config.stripe.publishableKey.startsWith('pk_test_')
);

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
const USE_SAVED_CARD = 'USE_SAVED_CARD';

const applyAsync = (acc, val) => acc.then(val);
const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
    ? PAY_AND_SAVE_FOR_LATER_USE
    : ONETIME_PAYMENT;
};

const initializeOrderPage = (initialValues, routes, dispatch) => {
  const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

  // Transaction is already created, but if the initial message
  // sending failed, we tell it to the OrderDetailsPage.
  dispatch(OrderPage.setInitialValues(initialValues));
};

const checkIsPaymentExpired = existingTransaction => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
    ? minutesBetween(existingTransaction.attributes.lastTransitionedAt, new Date()) >= 15
    : false;
};

// Convert the picked date to moment that will represent the same time of day in UTC time zone.
const bookingDatesMaybe = bookingDates => {
  const apiTimeZone = 'Etc/UTC';
  return bookingDates
    ? {
        bookingDates: {
          bookingStart: timeOfDayFromLocalToTimeZone(bookingDates.bookingStart, apiTimeZone),
          bookingEnd: timeOfDayFromLocalToTimeZone(bookingDates.bookingEnd, apiTimeZone),
        },
      }
    : {};
};

const createListingURL = (routes, listing) => {
  const id = listing.id.uuid;
  const slug = createSlug(listing.attributes.title);

  const linkProps = {
    name: 'ListingPage',
    params: { id, slug },
  };

  return createResourceLocatorString(linkProps.name, routes, linkProps.params, {});
};

export class CheckoutPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
      selectedPaymentType: '',
      isOpen: false,
      isModalAccepted: false,
    };
    this.stripe = null;

    this.onStripeInitialized = this.onStripeInitialized.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.customPricingParams = this.customPricingParams.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmitChargeCustomer = this.handleSubmitChargeCustomer.bind(this);
  }

  componentDidMount() {
    if (window) {
      this.loadInitialData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState.pageData) !== JSON.stringify(this.state.pageData)) {
      const { listing, transaction, fetchSpeculatedTransaction } = this.props;

      storeData(this.state.pageData?.orderData, listing, transaction, STORAGE_KEY);
    }
  }


  onModalAccept = () => {
    this.setState({
      isModalAccepted: true,
      isOpen: false,
      step: 2,
    });
  };

  toggleModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  /**
   * Constructs a request params object that can be used when creating bookings
   * using custom pricing.
   * @param {} params An object that contains bookingStart, bookingEnd and listing
   * @return a params object for custom pricing bookings
   */

  customPricingParams(params) {
    const { bookingStart, bookingEnd, listing, quantity, priceAddons, seats, ...rest } = params;
    const { amount, currency } = listing ? listing.attributes.price : {};

    const unitType = config.bookingUnitType;
    const priceAddonsLineItems =
      priceAddons && priceAddons.length
        ? priceAddons.map(addon => {
            if (addon.code) {
              return addon;
            }
            return {
              code: addonLabelIntoCode(addon.label),
              unitPrice: new Money(addon.amount, addon.currency),
              quantity: quantity,
            };
          })
        : [];

    const addonsTotalPrice = priceAddonsLineItems.reduce((acc, curr) => {
      return acc + curr.unitPrice.amount * curr.quantity;
    }, 0);
    const totalPrice = amount * quantity * seats + addonsTotalPrice;
    const cardFeeAmount = totalPrice * config.creditCardExtraFee; // 5%
    const listingId = listing && listing.id ? new UUID(listing.id.uuid) : '';
    if (
      (rest && rest.paymentType === PAYMENT_TYPES.card) ||
      this.state.selectedPaymentType === PAYMENT_TYPES.card
    ) {
      return {
        listingId: listingId,
        bookingStart,
        bookingEnd,
        lineItems: [
          ...priceAddonsLineItems,
          {
            code: LINE_ITEM_CARD_SERVICE_FEE,
            unitPrice: new Money(cardFeeAmount, currency),
            quantity: 1,
          },
          {
            code: unitType,
            unitPrice: new Money(amount, currency),
            units: quantity,
            seats: Number(seats),
          },
        ],
        ...rest,
      };
    } else {
      return {
        listingId: listingId,
        bookingStart,
        bookingEnd,
        lineItems: [
          ...priceAddonsLineItems,
          {
            code: unitType,
            unitPrice: new Money(amount, currency),
            units: quantity,
            seats: Number(seats),
          },
        ],
        ...rest,
      };
    }
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  loadInitialData() {
    const {
      orderData,
      listing,
      transaction,
      fetchSpeculatedTransaction,
      fetchStripeCustomer,
      history,
    } = this.props;

    // Fetch currentUser with stripeCustomer entity
    // Note: since there's need for data loading in "componentWillMount" function,
    //       this is added here instead of loadData static function.
    fetchStripeCustomer();

    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';

    // const hasDataInProps = !!(bookingData && bookingDates && listing) && hasNavigatedThroughLink;
    const hasDataInProps = !!(orderData && listing && hasNavigatedThroughLink);
    if (hasDataInProps) {
      // Store data only if data is passed through props and user has navigated through a link.
      storeData(orderData, listing, transaction, STORAGE_KEY);
    }
    // NOTE: stored data can be empty if user has already successfully completed transaction.
    const pageData = hasDataInProps ? { orderData, listing, transaction } : storedData(STORAGE_KEY);

    this.setState({ pageData: pageData || {}, dataLoaded: true });
  }

  handlePaymentIntent(handlePaymentParams) {
    const {
      currentUser,
      stripeCustomerFetched,
      onInitiateOrder,
      onConfirmCardPayment,
      onConfirmPayment,
      onAddEventToCalendar,
      onSendMessage,
      onSavePaymentMethod,
    } = this.props;

    const {
      pageData,
      speculatedTransaction,
      message,
      paymentIntent,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
      kidsName,
    } = handlePaymentParams;
    const storedTx = ensureTransaction(pageData.transaction);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    let createdPaymentIntent = null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const hasPaymentIntents =
        storedTx.attributes.protectedData && storedTx.attributes.protectedData.stripePaymentIntents;

      // If paymentIntent exists, order has been initiated previously.
      return hasPaymentIntents ? Promise.resolve(storedTx) : onInitiateOrder(fnParams, storedTx.id);
    };

    // Step 2: pay using Stripe SDK
    const fnHandleCardPayment = fnParams => {
      // fnParams should be returned transaction entity

      const order = ensureTransaction(fnParams);
      if (order.id) {
        // Store order.
        const { orderData, listing } = pageData;
        storeData(orderData, listing, order, STORAGE_KEY);
        this.setState({ pageData: { ...pageData, transaction: order } });
      }

      const hasPaymentIntents =
        order.attributes.protectedData && order.attributes.protectedData.stripePaymentIntents;

      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? order.attributes.protectedData.stripePaymentIntents.default
        : null;

      const { stripe, card, billingDetails, paymentIntent } = handlePaymentParams;
      // const stripeElementMaybe = selectedPaymentFlow !== USE_SAVED_CARD ? { card } : {};
      const stripeElementMaybe = card ? { card } : {};

      // Note: payment_method could be set here for USE_SAVED_CARD flow.
      // { payment_method: stripePaymentMethodId }
      // However, we have set it already on API side, when PaymentIntent was created.
      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
              payment_method: {
                billing_details: billingDetails,
              },
            }
          : {};

      const params = {
        stripePaymentIntentClientSecret,
        orderId: order.id,
        stripe,
        ...stripeElementMaybe,
        paymentParams,
      };

      // If paymentIntent status is not waiting user action,
      // handleCardPayment has been called previously.
      const hasPaymentIntentUserActionsDone =
        paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);
      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({ transactionId: order.id, paymentIntent })
        : onConfirmCardPayment(params);
    };

    // Step 3: complete order by confirming payment to Marketplace API
    // Parameter should contain { paymentIntent, transactionId } returned in step 2
    const fnConfirmPayment = fnParams => {
      createdPaymentIntent = fnParams.paymentIntent;
      return onConfirmPayment(fnParams);
    };

    const fnAddEventToCalendar = fnParams => {
      return onAddEventToCalendar(fnParams);
    };

    // Step 4: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    // Step 5: optionally save card as defaultPaymentMethod
    const fnSavePaymentMethod = fnParams => {
      const pi = createdPaymentIntent || paymentIntent;

      if (selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE) {
        return onSavePaymentMethod(ensuredStripeCustomer, pi.payment_method)
          .then(response => {
            if (response.errors) {
              return { ...fnParams, paymentMethodSaved: false };
            }
            return { ...fnParams, paymentMethodSaved: true };
          })
          .catch(e => {
            // Real error cases are catched already in paymentMethods page.
            return { ...fnParams, paymentMethodSaved: false };
          });
      } else {
        return Promise.resolve({ ...fnParams, paymentMethodSaved: true });
      }
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnHandleCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnRequestPayment,
      fnHandleCardPayment,
      fnConfirmPayment,
      // fnAddEventToCalendar,
      fnSendMessage,
      fnSavePaymentMethod
    );

    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    const tx = speculatedTransaction ? speculatedTransaction : storedTx;

    const packageData = pageData.orderData?.packageData;
    const quantity = pageData.orderData?.quantity;

    const quantityMaybe = quantity ? { quantity } : {};

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.handleCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
        ? { setupPaymentMethodForSaving: true }
        : {};

    const tripData = this.state.tripDetails;
    const estimatedMinBudget = {
      amount: tripData.estimatedMinBudget.amount,
      currency: tripData.estimatedMinBudget.currency,
    };
    const estimatedMaxBudget = {
      amount: tripData.estimatedMaxBudget.amount,
      currency: tripData.estimatedMaxBudget.currency,
    };
    const updateTripData = { ...tripData, estimatedMinBudget, estimatedMaxBudget };

    const orderParams = {
      listingId: pageData.listing.id,
      orderTripDetails: updateTripData || {},
      packageData,
      ...bookingDatesMaybe(pageData.orderData.bookingDates),
      ...quantityMaybe,
      ...optionalPaymentParams,
    };

    return handlePaymentIntentCreation(orderParams);
  }

  handleSubmit(values) {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    const { history, speculatedTransaction, currentUser, paymentIntent, dispatch } = this.props;
    const { card, message, paymentMethod, formValues } = values;
    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      saveAfterOnetimePayment,
      kidsName,
    } = formValues;
    // Billing address is recommended.
    // However, let's not assume that <StripePaymentAddress> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const requestPaymentParams = {
      pageData: this.state.pageData,
      speculatedTransaction,
      stripe: this.stripe,
      card,
      billingDetails,
      message,
      paymentIntent,
      selectedPaymentMethod: paymentMethod,
      saveAfterOnetimePayment: !!saveAfterOnetimePayment,
      kidsName,
    };

    this.handlePaymentIntent(requestPaymentParams)
      .then(res => {
        const { orderId, messageSuccess, paymentMethodSaved } = res;
        this.setState({ submitting: false });

        const routes = routeConfiguration();
        const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
        const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, { id: orderId.uuid });
        const initialValues = {
          initialMessageFailedToTransaction,
          savePaymentMethodFailed: !paymentMethodSaved,
        };

        initializeOrderPage(initialValues, routes, dispatch);
        clearData(STORAGE_KEY);
        history.push(orderDetailsPath);
      })
      .catch(err => {
        console.error(err);
        this.setState({ submitting: false });
      });
  }

  async handleSubmitChargeCustomer(data) {
    if (this.state.submitting) {
      return;
    }
    const pageData = this.state.pageData;
    try {
      const { stripeTokenId, amountToPay, speculatedTxId, billingAddress } = data;
      this.setState({ submitting: true });

      const {
        listing,
        history,
        speculatedTransaction,
        currentUser,
        dispatch,
        onAddChargeToOrder,
        callTrackAnalyticsEventWithContext,
      } = this.props;


      let newTransaction;
      const hasTransaction = !!pageData.transaction;
      const currencyToPay = pageData?.transaction?.attributes?.payinTotal?.currency;
      if (hasTransaction) {
        console.log(`Adding charge to existing transaction: ${pageData.transaction.id.uuid}`);
        newTransaction = await onAddChargeToOrder({
          stripeTokenId,
          transactionId: pageData.transaction.id.uuid,
          billingAddress,
          amountToPay: {amount: amountToPay, currency: currencyToPay},
        });
      } else {
        // Something has gone wrong
        console.log(`Missing transaction, cannot create charge.`);
      }

      this.setState({ submitting: false });
      const orderId = newTransaction.id;
      const routes = routeConfiguration();
      const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, { id: orderId.uuid });
      const initialValues = {
        initialMessageFailedToTransaction: null,
        savePaymentMethodFailed: false,
      };

      initializeOrderPage(initialValues, routes, dispatch);
      clearData(STORAGE_KEY);
      history.push(orderDetailsPath);

      // Track the key/conversion event.
      // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#purchase
      try {
        callTrackAnalyticsEventWithContext('purchase', {
          currency: 'USD',
          value: (amountToPay ?? 0) / 100,
          transaction_id: orderId.uuid,
          items: [{item_name: 'Researched Itinerary'}],
        });
      } catch(error) {
        console.error(error);
      }
    } catch(error) {
      console.error(error);
      if (error.fetchedTransaction) {
        // Update component state to include transaction, allowing the
        // transaction to be potentially be reused and retry adding a charge.
        storeData(
          pageData.orderData,
          pageData.listing,
          error.fetchedTransaction,
          STORAGE_KEY
        );
        this.setState({
          pageData: {...pageData, transaction: error.fetchedTransaction }
        });
      }
      this.setState({ submitting: false });
    };
  }

  onStripeInitialized(stripe) {
    this.stripe = stripe;

    const { paymentIntent, onRetrievePaymentIntent } = this.props;
    const tx = this.state.pageData ? this.state.pageData.transaction : null;

    // We need to get up to date PI, if booking is created but payment is not expired.
    const shouldFetchPaymentIntent =
      this.stripe &&
      !paymentIntent &&
      tx &&
      tx.id &&
      txIsPaymentPending(tx) &&
      !checkIsPaymentExpired(tx);

    if (shouldFetchPaymentIntent) {
      const { stripePaymentIntentClientSecret } =
        tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
          ? tx.attributes.protectedData.stripePaymentIntents.default
          : {};

      // Fetch up to date PaymentIntent from Stripe
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret });
    }
  }

  render() {
    const {
      scrollingDisabled,
      speculateTransactionInProgress,
      speculateTransactionError,
      speculatedTransaction: speculatedTransactionMaybe,
      initiateOrderError,
      confirmPaymentError,
      intl,
      params,
      currentUser,
      confirmCardPaymentError,
      paymentIntent,
      retrievePaymentIntentError,
      stripeCustomerFetched,
      recentTransactions,
      currencyConversionRate,
      callTrackAnalyticsEventWithContext,
    } = this.props;

    // Since the listing data is already given from the ListingPage
    // and stored to handle refreshes, it might not have the possible
    // deleted or closed information in it. If the transaction
    // initiate or the speculative initiate fail due to the listing
    // being deleted or closec, we should dig the information from the
    // errors and not the listing data.
    const listingNotFound = //TODO check this still works
      isTransactionInitiateListingNotFoundError(speculateTransactionError) ||
      isTransactionInitiateListingNotFoundError(initiateOrderError);

    const isLoading = !this.state.dataLoaded || speculateTransactionInProgress;

    const { listing, bookingDates, orderData, transaction } = this.state.pageData;
    const existingTransaction = ensureTransaction(transaction);
    const payinTotal = existingTransaction?.attributes?.payinTotal || new Money(0, 'USD');
    const amount = convertMoneyToNumber(payinTotal);
    const currency = payinTotal.currency;
    const tripDetails = existingTransaction?.attributes?.protectedData?.tripDetails;
    const providerName = existingTransaction?.provider?.attributes?.profile?.displayName;
    const speculatedTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);
    const currentListing = ensureListing(listing);
    const currentAuthor = ensureUser(currentListing.author);

    const listingTitle = currentListing.attributes.title;
    const title = intl.formatMessage({ id: 'CheckoutPage.title' }, { listingTitle });

    const pageProps = { title, scrollingDisabled };
    const topbar = (
      <TopbarContainer
        className={css.topbar}
        mobileRootClassName={css.mobileTopbar}
        desktopClassName={css.desktopTopbar}
        currentPage={`paymentpage`}
      />
    );

    if (isLoading) {
      return <Page {...pageProps}>{topbar}</Page>;
    }

    const isOwnListing =
      currentUser &&
      currentUser.id &&
      currentAuthor &&
      currentAuthor.id &&
      currentAuthor.id.uuid === currentUser.id.uuid;

    const hasRequiredData = !!(currentListing.id && currentAuthor.id);
    const canShowPage = hasRequiredData && !isOwnListing;
    const shouldRedirect = !isLoading && !canShowPage;

    // Redirect back to ListingPage if data is missing.
    // Redirection must happen before any data format error is thrown (e.g. wrong currency)

    if (shouldRedirect) {
      // eslint-disable-next-line no-console
      console.error('Missing or invalid data for checkout, redirecting back to listing page.', {
        transaction: speculatedTransaction,
        listing,
      });
      return <NamedRedirect name="ListingPage" params={params} />;
    }

    // Show breakdown only when (speculated?) transaction is loaded
    // (i.e. have an id and lineItems)
    const tx = existingTransaction;
    const txBookingMaybe = tx.booking?.id
      ? { booking: ensureBooking(tx.booking), dateType: DATE_TYPE_DATE }
      : {};
    const breakdown =
      tx.id && tx.attributes.lineItems?.length > 0 ? (
        <OrderBreakdown
          className={css.bookingBreakdown}
          userRole="customer"
          unitType={config.bookingUnitType}
          transaction={tx}
          listing={currentListing}
          currencyConversionRate={currencyConversionRate}
          {...txBookingMaybe}
          taxJar={<TaxJarInfo lineItems={tx.attributes.lineItems} listing={listing} />}
        />
      ) : null;

    const isPaymentExpired = checkIsPaymentExpired(existingTransaction);
    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
      ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
    );

    const canRetryPayment = isTransactionInitiateOrderErrorTemporary(
      initiateOrderError
    );

    // Allow showing page when currentUser is still being downloaded,
    // but show payment form only when user info is loaded.
    const showPaymentForm = !!(
      currentUser &&
      hasRequiredData &&
      !listingNotFound &&
      (!initiateOrderError || canRetryPayment) &&
      !speculateTransactionError &&
      !retrievePaymentIntentError &&
      !isPaymentExpired
    );

    const featureImage =
      currentListing.images && currentListing.images.length > 1 ? currentListing.images[1] : null;

    const listingLink = (
      <NamedLink
        name="ListingPage"
        params={{ id: currentListing?.id?.uuid, slug: createSlug(listingTitle) }}
      >
        <FormattedMessage id="CheckoutPage.errorlistingLinkText" />
      </NamedLink>
    );

    const isAmountTooLowError = isTransactionInitiateAmountTooLowError(initiateOrderError);
    const isChargeDisabledError = isTransactionChargeDisabledError(initiateOrderError);
    const isBookingTimeNotAvailableError = isTransactionInitiateBookingTimeNotAvailableError(
      initiateOrderError
    );
    const isChargeCardError = isTransactionCardError(initiateOrderError);
    const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

    let initiateOrderErrorMessage = null;
    let listingNotFoundErrorMessage = null;

    if (listingNotFound) {
      listingNotFoundErrorMessage = (
        <p className={css.notFoundError}>
          <FormattedMessage id="CheckoutPage.listingNotFoundError" />
        </p>
      );
    } else if (isAmountTooLowError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (isBookingTimeNotAvailableError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isChargeDisabledError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />
        </p>
      );
    } else if (stripeErrors && stripeErrors.length > 0) {
      // NOTE: Error messages from Stripes are not part of translations.
      // By default they are in English.
      const stripeErrorsAsString = stripeErrors.join(', ');
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="CheckoutPage.initiateOrderStripeError"
            values={{ stripeErrors: stripeErrorsAsString }}
          />
        </p>
      );
    } else if (initiateOrderError) {
      // Generic initiate order error
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderError" values={{ listingLink }} />
        </p>
      );
    }

    const speculateTransactionErrorMessage = speculateTransactionError ? (
      <p className={css.speculateError}>
        <FormattedMessage id="CheckoutPage.speculateTransactionError" />
      </p>
    ) : null;
    let speculateErrorMessage = null;

    if (isTransactionInitiateMissingStripeAccountError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.providerStripeAccountMissingError" />
        </p>
      );
    } else if (isTransactionInitiateBookingTimeNotAvailableError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (speculateTransactionError) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.speculateFailedMessage" />
        </p>
      );
    }

    const showInitialMessageInput = !(
      existingTransaction && existingTransaction.attributes.lastTransition === TRANSITION_ENQUIRE
    );

    // const selectedPaymentMethod = currentListing.attributes.publicData.payment_method &&
    //   currentListing.attributes.publicData.payment_method.length !== 0 ?
    //   currentListing.attributes.publicData.payment_method :
    //   ['cash', 'card', 'member', 'bank', 'hitpay']

    const selectedPaymentMethod = ['card'];

    // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
    const userName =
      currentUser && currentUser.attributes
        ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
        : null;

    // If paymentIntent status is not waiting user action,
    // handleCardPayment has been called previously.
    const hasPaymentIntentUserActionsDone =
      paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

    // If your marketplace works mostly in one country you can use initial values to select country automatically
    // e.g. {country: 'FI'}

    const initalValuesForStripePayment = { name: userName };
    const credits = params.credits;
    const { step } = this.state;
    const DetailsFormInitialValues = {
      includeTransport: ['yes'],
      includeAccommodation: ['yes'],
    };
    //
    return (
      <Page {...pageProps} className={css.pageContainer}>
        <TopbarContainer
          className={css.topbar}
          mobileRootClassName={css.mobileTopbar}
          desktopClassName={css.desktopTopbar}
        />
        <div className={css.contentContainer}>
          <div className={css.bookListingContainer}>
            <section className={css.paymentContainer}>
              {initiateOrderErrorMessage}
              {listingNotFoundErrorMessage}
              {speculateErrorMessage}
              <h2>Checkout</h2>
              <p className={css.checkoutInfoClass}>
                <FormattedMessage
                  id="ChargeCustomerForm.checkoutInfo"
                  values={{
                    providerName,
                    amount,
                    currency,
                  }}
                />
              </p>
              {/* testing only start */}
              {isStripeTestMode && (
                <div className={css.testNotification}>
                  <div>
                    <img src={WarningIcon} alt="For testing only" height={60} />
                  </div>
                  <p className={css.notificationTitle}>
                    <FormattedMessage
                      id="ChargeCustomerForm.testNotificationTitle"
                      values={{
                        b: (...chunks) => <b>{chunks}</b>,
                      }}
                    />
                  </p>
                  <FormattedMessage
                    id="ChargeCustomerForm.testNotificationInfo"
                    values={{
                      br: <br />,
                      em: (...chunks) => <em>{chunks}</em>,
                      a: (...chunks) => <a href="https://lyfshort.com/support/">{chunks}</a>,
                      b: (...chunks) => <b>{chunks}</b>,
                    }}
                  />
                </div>
              )}
              {/* testing only end */}
              {retrievePaymentIntentError ? (
                <p className={css.orderError}>
                  <FormattedMessage
                    id="CheckoutPage.retrievingStripePaymentIntentFailed"
                    values={{ listingLink }}
                  />
                </p>
              ) : null}
              {showPaymentForm ? (
                <ChargeCustomerForm
                  onSubmit={this.handleSubmitChargeCustomer}
                  listing={currentListing}
                  pageData={this.state.pageData}
                  speculatedTransaction={tx}
                />
              ) : null}
              {isPaymentExpired ? (
                <p className={css.orderError}>
                  <FormattedMessage
                    id="CheckoutPage.paymentExpiredMessage"
                    values={{ listingLink }}
                  />
                </p>
              ) : null}
              {!!isChargeCardError && !showPaymentForm && (
                <Button
                  type="button"
                  onClick={() => {
                    this.props.onChangeDeclinedCard();
                  }}
                >
                  Change Payment Method
                </Button>
              )}
            </section>
          </div>

          <div className={css.detailsContainerDesktop}>
            <div className={css.detailsAspectWrapper}>
              <ResponsiveImage
                rootClassName={css.rootForImage}
                alt={listingTitle}
                image={featureImage}
                variants={['landscape-crop', 'landscape-crop2x']}
              />
            </div>
            <div className={css.ContainerLine}></div>
            <div className={css.Summary}>
              <div className={css.SummaryHead}>
                <div className={css.detailsHeadings}>
                  <h2 className={css.detailsTitle}>
                    <UserDisplayName user={currentAuthor} intl={intl} />
                  </h2>
                </div>
              </div>

              <div>
                <TripSummary
                  intl={intl}
                  {...tripDetails}
                />
              </div>

              {speculateTransactionErrorMessage}
              {breakdown}
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

CheckoutPageComponent.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
  paymentIntent: null,
};

CheckoutPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  fetchStripeCustomer: func.isRequired,
  stripeCustomerFetched: bool.isRequired,
  fetchSpeculatedTransaction: func.isRequired,
  speculateTransactionInProgress: bool.isRequired,
  speculateTransactionError: propTypes.error,
  speculatedTransaction: propTypes.transaction,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  onConfirmPayment: func.isRequired,
  onInitiateOrder: func.isRequired,
  onConfirmCardPayment: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onSendMessage: func.isRequired,
  onHitPay: func.isRequired,
  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  // confirmCardPaymentError comes from Stripe so that's why we can't expect it to be in a specific form
  confirmCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,

  // from connect
  dispatch: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    listing,
    orderData,
    bookingData,
    bookingDates,
    stripeCustomerFetched,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
  } = state.CheckoutPage;
  const { currentUser, recentTransactions, currencyConversionRate } = state.user;
  const { confirmCardPaymentError, paymentIntent, retrievePaymentIntentError } = state.stripe;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    stripeCustomerFetched,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    listing,
    orderData,
    initiateOrderError,
    confirmCardPaymentError,
    confirmPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    recentTransactions,
    currencyConversionRate,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchSpeculatedTransaction: (params, transactionId) =>
    dispatch(speculateTransaction(params, transactionId)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onInitiateOrder: (params, transactionId) => dispatch(initiateOrder(params, transactionId)),
  onAddChargeToOrder: (params) =>
    dispatch(addChargeToOrder(params)),
  onRetrievePaymentIntent: params => dispatch(retrievePaymentIntent(params)),
  onConfirmCardPayment: params => dispatch(confirmCardPayment(params)),
  onConfirmPayment: params => dispatch(confirmPayment(params)),
  onAddEventToCalendar: params => dispatch(addEventToCalendar(params)),
  onSendMessage: params => dispatch(sendMessage(params)),
  onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
  onHitPay: params => dispatch(hitPay(params)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callTrackAnalyticsEventWithContext: (action, params) => (
    dispatch(trackAnalyticsEventWithContext(action, params))
  ),
  onChangeDeclinedCard: () => dispatch(clearInitiateOrderError()),
});

const CheckoutPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CheckoutPageComponent);

CheckoutPage.setInitialValues = (initialValues, saveToSessionStorage = true) => {
  if (saveToSessionStorage) {
    const { listing, orderData, transaction } = initialValues;
    storeData(orderData, listing, transaction, STORAGE_KEY);
  }

  return setInitialValues(initialValues);
};

CheckoutPage.displayName = 'CheckoutPage';

export default CheckoutPage;
