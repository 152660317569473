import React from 'react';
import css from '../ListingDetailsSectionMainTab.css';
import { AvatarLarge, Button } from 'components';
import Basic_List_Icon1 from '../../../assets/Listing_Page/edit.svg';
import Basic_List_Icon2 from '../../../assets/Listing_Page/users.svg';
import Basic_List_Icon3 from '../../../assets/Listing_Page/rotate-cw.svg';
import Basic_List_Icon4 from '../../../assets/Listing_Page/message-square.svg';
import { convertMoneyToNumber, getRefCurrencyValue } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { currencyDB } from 'lib/currency-list';

const { Money } = sdkTypes;

const SubTabSectionDetailsContent = (props) => {
  const {
    avatarDetails,
    currentListing,
    intl,
    tabName,
    tabIndex,
    handlePackagePurchase,
    isDraftView,
    currencyConversionRate,
    ...rest
  } = props;
  const { to: aimedCurrency = 'USD', rate = 1 } = currencyConversionRate;
  const { publicData, price } = currentListing.attributes;
  const listingCurrency = publicData.base_currency || 'USD';
  const estimatedRefPrice = getRefCurrencyValue(intl, rate, price);
  const refCurrencySign = currencyDB.find(c => c.value === aimedCurrency)?.sign;
  const priceInNumber = price ? convertMoneyToNumber(price) : '15';


  const handleSubmit = (e) => {
    if (isDraftView) {
      e.preventDefault();
    } else {
      handlePackagePurchase({
        ...publicData?.packages[tabIndex],
        price,
        packageLable: tabName,
      })
    }
  }

  const BasicItemList = [
    {
      id: 'list1',
      title: `Interactive Itinerary`,
      src: Basic_List_Icon1,
    },
    {
      id: 'list2',
      title: `Online Collaboration`,
      src: Basic_List_Icon2,
    },
    {
      id: 'list3',
      title: `Supplier Quotes`,
      src: Basic_List_Icon3,
    },
    {
      id: 'list4',
      title: `Group Messaging`,
      src: Basic_List_Icon4,
    },
  ];

  return (
    <div className={css.subTabSectionContainer}>
      <div>
        <div className={css.userInformation}>
          <div className={css.packageHeading}>PERSONALISED TRIP PLAN</div>
          <div className={css.userInformationWrap}>
            <div className={css.userListItem}>
              {BasicItemList.map(list => (
                <li key={list.id}>
                  <img src={list.src} alt={list.id} />
                  <h5>{list.title}</h5>
                </li>
              ))}
            </div>
            <div className={css.authorDetailsImageWrapper}>
              <div className={css.authorDetailsImageRelative}>
                <AvatarLarge
                  className={css.authorDetailsImageLarge}
                  user={avatarDetails}
                  disableProfileLink
                />
              </div>
              <div className={css.supContent}>
                <div className={css.fromPriceCurrencyTextWrapper}>
                  <div>*From</div>
                  <div>{aimedCurrency}</div>
                </div>
                <div className={css.priceAmountText}>{` ${refCurrencySign}${estimatedRefPrice}`}</div>
              </div>
              <div
                className={css.refCurrencyContent}
              >{`(${listingCurrency} $${priceInNumber})`}</div>
            </div>
          </div>
          <div className={css.bottomText}>
            <p>
              * Individual Expert service quote will be provided following initial enquiry, in standardised USD.
            </p>
          </div>
        </div>

        <div className={css.subTabBtnWrap}>
          <Button rootClassName={css.sendRequestButton} onClick={handleSubmit}>
            Enquire Now
          </Button>
        </div>
      </div>
      <h3>WHAT YOU GET</h3>
      <p>
        <span className={css.boldBlackText}>Research, Supplier Quotes, Itinerary & Collaboration</span><br />
        Upon receiving your high level trip enquiry ideas I will quote a price to research, find suitable suppliers and costs then create &
        share a personalised online itinerary in the Lyfshort Organiser App. We, or others going on the trip, can then collaborate within the itinerary using group messaging.
      </p>
      <p>
        <span className={css.boldBlackText}>Optional Booking & Support</span><br />
        Following the collaborative process of suggesting itinerary details and pricing options, I can then go on and book and support them for you as a
        <span className={css.boldGreyItalicText}> Certified Travel Agent</span> ... but don't have to.
      </p>
    </div>
  );
};

export default SubTabSectionDetailsContent;
