import React from 'react';
import { convertMoneyTypeToNumber } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import moment from 'moment';
import { UserDisplayName } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './TripSummary.css';
import { currencyDB } from 'lib/currency-list';
const { Money } = sdkTypes;

const TripSummary = props => {
  const {
    intl,
    tripName,
    tripType,
    estimatedMinBudget,
    estimatedMaxBudget,
    budgetCurrency,
    tripLocation,
    precision,
    originLocation,
    targetStartDate,
    targetEndDate,
    timePrecision,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    includeTransport,
    transportType,
    transportPricingType,
    transportPreferredCarrier,
    transportRewardsMembership,
    accommPreferredBrand,
    accommRewardsMembership,
    includeAccommodation,
    accommodationType,
    includeDineInMeals,
    numberOfBreakfast,
    breakfastVenueType,
    numberOfLunch,
    lunchVenueType,
    numberOfDinner,
    dinnerVenueType,
    mealPricing,
    mealPricing1,
    mealPricing2,
    includeActivities,
    researchActivities,
    guidance,
  } = props;
  const currencySign = currencyDB.find(i => i.value === budgetCurrency)?.sign;

  const transportInclude =
    includeTransport && includeTransport.includes('yes') && (transportType || accommodationType);
  const accommodationInclude = includeAccommodation && includeAccommodation.includes('yes');
  const mealsInclude =
    includeDineInMeals &&
    includeDineInMeals.includes('yes') &&
    (numberOfBreakfast || numberOfLunch || numberOfDinner);
  const activitiesInclude = includeActivities && includeActivities.includes('yes');
  return (
    <div className={css.root}>
      <h4 className={css.summaryTitle}>
        <FormattedMessage id="TripSummary.requestSummary" />
      </h4>
      <hr className={css.totalDivider} />
      {tripName && (
        <div className={css.summaryWrapper}>
          <span className={css.summaryName}>
            <FormattedMessage id="TripSummary.what" />
          </span>
          <div className={css.summaryDetails}>
            <FormattedMessage
              id="TripSummary.budget"
              values={{
                tripName,
                tripType,
                budgetCurrency: budgetCurrency ?? estimatedMinBudget?.currency,
                currencySign,
                minAmount:
                  estimatedMinBudget?.amount && estimatedMinBudget?.currency
                    ? intl.formatNumber(convertMoneyTypeToNumber(estimatedMinBudget))
                    : '$0.00',
                maxAmount:
                  estimatedMaxBudget?.amount && estimatedMaxBudget?.currency
                    ? intl.formatNumber(convertMoneyTypeToNumber(estimatedMaxBudget))
                    : '$0.00',
              }}
            />
          </div>
        </div>
      )}

      {tripLocation?.search && (
        <div className={css.summaryWrapper}>
          <span className={css.summaryName}>
            <FormattedMessage id="TripSummary.where" />
          </span>
          <div className={css.summaryDetails}>
            <FormattedMessage
              id="TripSummary.tripLocation"
              values={{
                tripLocation: tripLocation?.search,
                originLocation: originLocation?.search,
                precision,
                br: <br />,
              }}
            />
          </div>
        </div>
      )}

      {targetStartDate && targetEndDate && (
        <div className={css.summaryWrapper}>
          <span className={css.summaryName}>
            <FormattedMessage id="TripSummary.when" />
          </span>
          <div className={css.summaryDetails}>
            <span>{`From: ${moment(new Date(targetStartDate)).format('ddd Do MMM YYYY')}`}</span>
            <span>{`To: ${moment(new Date(targetEndDate)).format('ddd Do MMM YYYY')}`}</span>
            <span>{`Precision: ${timePrecision}`}</span>
          </div>
        </div>
      )}

      {(numberOfAdults || numberOfChildren || numberOfInfants) && (
        <div className={css.summaryWrapper}>
          <span className={css.summaryName}>
            <FormattedMessage id="TripSummary.Who" />
          </span>

          <div className={css.summaryDetails}>
            <FormattedMessage
              id="TripSummary.whoIncluded"
              values={{
                numberOfAdults: numberOfAdults || 0,
                adultsUnit: numberOfAdults === 1 ? 'Adult' : 'Adults',
                numberOfChildren: numberOfChildren || 0,
                childrenUnit: numberOfChildren === 1 ? 'Child (2 - 12 yo)' : 'Children (2 - 12yo)',
                numberOfInfants: numberOfInfants || 0,
                infantsUnit: numberOfInfants === 1 ? 'Infant' : 'Infants',
              }}
            />
          </div>
        </div>
      )}

      {transportInclude && (
        <div className={css.summaryWrapper}>
          <span className={css.summaryName}>
            <FormattedMessage id="TripSummary.Transport" />
          </span>

          <div className={css.summaryDetails}>
            {transportInclude && transportType && (
              <>
                <FormattedMessage
                  id="TripSummary.transportIncludeMain"
                  values={{
                    br: <br />,
                    i: chunks => <i>{chunks}</i>,
                  }}
                />
                {transportPricingType && (
                  <FormattedMessage
                    id="TripSummary.transportPricingType"
                    values={{
                      transportPricingType,
                    }}
                  />
                )}
                {transportPreferredCarrier && (
                  <FormattedMessage
                    id="TripSummary.transportPreferredCarrier"
                    values={{
                      transportPreferredCarrier,
                      transportRewardsMembership:
                        transportRewardsMembership && `/ ${transportRewardsMembership}`,
                    }}
                  />
                )}
                {transportType === 'All' && (
                  <FormattedMessage
                    id="TripSummary.transportIncludeOnDemand"
                    values={{
                      br: <br />,
                      i: chunks => <i>{chunks}</i>,
                    }}
                  />
                )}
                {transportType === 'Main Only' && (
                  <FormattedMessage
                    id="TripSummary.transportExcludeOnDemand"
                    values={{
                      br: <br />,
                      i: chunks => <i>{chunks}</i>,
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}
      {accommodationInclude && accommodationType && (
        <div className={css.summaryWrapper}>
          <span className={css.summaryName}>
            <FormattedMessage id="TripSummary.Accom" />
          </span>

          <div className={css.summaryDetails}>
            {accommodationInclude && accommodationType && (
              <FormattedMessage
                id="TripSummary.accommIncluded"
                values={{ accommodationType }}
              />
            )}
            {accommPreferredBrand && (
              <FormattedMessage
                id="TripSummary.accommPreferredBrand"
                values={{
                  accommPreferredBrand,
                  accommRewardsMembership:
                    accommRewardsMembership && `/ ${accommRewardsMembership}`,
                }}
              />
            )}
          </div>
        </div>
      )}

      {!!mealsInclude && (
        <div className={css.summaryWrapper}>
          <span className={css.summaryName}>
            <FormattedMessage id="TripSummary.meals" />
          </span>

          <div className={css.summaryDetails}>
            {!!numberOfBreakfast && (
              <FormattedMessage
                id="TripSummary.breakfast"
                values={{ numberOfBreakfast, breakfastVenueType }}
              />
            )}
            {!!numberOfBreakfast && mealPricing && (
              <FormattedMessage id="TripSummary.mealPricing" values={{ mealPricing }} />
            )}
            {!!numberOfLunch && (
              <FormattedMessage id="TripSummary.Lunch" values={{ numberOfLunch, lunchVenueType }} />
            )}
            {!!numberOfLunch && mealPricing1 && (
              <FormattedMessage
                id="TripSummary.mealPricing"
                values={{ mealPricing: mealPricing1 }}
              />
            )}
            {!!numberOfDinner && (
              <FormattedMessage
                id="TripSummary.Dinner"
                values={{ numberOfDinner, dinnerVenueType }}
              />
            )}
            {!!numberOfDinner && mealPricing2 && (
              <FormattedMessage
                id="TripSummary.mealPricing"
                values={{ mealPricing: mealPricing2 }}
              />
            )}
          </div>
        </div>
      )}

      {activitiesInclude && researchActivities && (
        <div className={css.summaryWrapper}>
          <span className={css.summaryName}>
            <FormattedMessage id="TripSummary.activities" />
          </span>
          <div className={css.summaryDetails}>
            <span>{researchActivities && researchActivities.join(', ')}</span>
          </div>
        </div>
      )}

      {guidance && (
        <div className={css.summaryWrapper}>
          <span className={css.summaryName}>
            <FormattedMessage id="TripSummary.guidance" />
          </span>
          <div className={css.summaryDetails}>
            <span>{guidance}</span>
          </div>
        </div>
      )}
      
    </div>
  );
};

export default TripSummary;
