import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { TopbarContainer } from 'containers';
import {
  Page,
  TripSummary,
  ResponsiveImage,
  UserDisplayName,
  Modal,
  Button,
} from 'components';
import cns from 'classnames';
import css from './CustomerRequirementPage.css';
import { createResourceLocatorString, findRouteByRouteName } from 'util/routes';
import { createSlug } from 'util/urlHelpers';
import { ensureListing, ensureUser } from 'util/data';
import { storeData, storedData } from 'containers/CustomerRequirementPage/CustomerRequirementPageSessionHelper';
import { initiateEnquiry, initiateOrder, setInitialValues } from './CustomerRequirementPage.duck';
import { formatMoney } from 'util/currency';
import TripRequirementForm from './TripRequirementForm';
import routeConfiguration from 'routeConfiguration';
import { isScrollingDisabled, manageDisableScrolling } from 'ducks/UI.duck';

import lyfshortLogo from 'assets/Listing_Page/Lyfshort_logo_560X170.svg';
import modalImage from 'assets/Listing_Page/Traveller_girl2.png';

const STORAGE_KEY = 'CustomerRequirementPage';

const createListingURL = (routes, listing) => {
  const id = listing.id.uuid;
  const slug = createSlug(listing.attributes.title);

  const linkProps = {
    name: 'ListingPage',
    params: { id, slug },
  };

  return createResourceLocatorString(linkProps.name, routes, linkProps.params, {});
};

export const CustomerRequirementPageComponent = (props) => {
  const {
    scrollingDisabled,
    history,
    intl,
    currencyConversionRate,
    callSetInitialValues,
    isAuthenticated,
    onManageDisableScrolling,
    location,
    onInitiateEnquiry,
  } = props;

  const [step, setStep] = useState(1);
  const [pageData, setPageData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [tripDetails, setTripDetails] = useState({});
  const [loginPrompt, setLoginPrompt] = useState(false);

  const detailsFormRef = useRef(null);
  const tripFormRef = useRef(null);

  const loginPromptModalMessage = intl.formatMessage(
    {
      id: 'ListingPage.loginPromptModalMessage',
    },
    {
      bold: chunks => <strong>{chunks}</strong>,
    }
  );

  useEffect(() => {
    if (window) {
      loadInitialData();
    }

  }, [])
  
  useEffect(() => {
    const loginChecked = localStorage.getItem('loginChecked')
    if (dataLoaded && loginChecked) {
      onSubmit();
    }
  }, [dataLoaded])

  useEffect(() => {
    const { listing } = props;
    storeData(pageData?.orderData, listing, null, STORAGE_KEY);
  }, [JSON.stringify(pageData)])

  const { listing, orderData } = pageData;
  const currentListing = ensureListing(listing);
  const currentAuthor = ensureUser(currentListing.author);

  const featureImage = currentListing.images && currentListing.images.length > 1 ? currentListing.images[1] : null;
  const listingTitle = currentListing.attributes.title;
  const title = intl.formatMessage({ id: 'CustomerRequirementPage.title' }, { listingTitle })

  const packagePrice = storedData(STORAGE_KEY).orderData?.packageData?.price;
  const price = packagePrice || currentListing.attributes.price;
  const formattedPrice = formatMoney(intl, price);
  const detailsSubTitle = `${formattedPrice}`

  const initialFormValues = {
    includeTransport: ['yes'],
    includeAccommodation: ['yes']
  }


  // To handle saving in LocalStorage or other side effects
  const onChange = (fieldRenderProps) => {
    localStorage.setItem('tripDetails', JSON.stringify({ ...tripDetails, ...fieldRenderProps.values }));
    setTripDetails({ ...tripDetails, ...fieldRenderProps.values });
  }

  const onOverLimit = (itemLimit, participantLimit) => {
    // TODO: handle the case when the customer requirement is over the limit
  }

  const onSubmit = async () => {
    const routes = routeConfiguration();

    if (step <= 1 && !localStorage.getItem('loginChecked')) {
      setStep(step + 1)
      window.scrollTo(0, 0);
    } else if (!isAuthenticated) {
      if (!loginPrompt) {
        setLoginPrompt(true);
        return
      } else {
        const state = { from: `${location.pathname}${location.search}${location.hash}` }
        localStorage.setItem('loginChecked', true);
        history.push(createResourceLocatorString('LoginPage', routes), state)
      }
    } else {
      if (localStorage.getItem('loginChecked')) {
        localStorage.removeItem('loginChecked');
      }

      const tripData = JSON.parse(localStorage.getItem('tripDetails'));
      // The sharetribeSDK Money type used in `tripDetails` is incompatible with params object format so need to be transformed
      const { estimatedMaxBudget, estimatedMinBudget, ...otherDetails } = Object.keys(tripDetails).length === 0 ? tripData : tripDetails;

      const updatedTripDetails = {
        ...otherDetails,
        estimatedMaxBudget: {
          amount: estimatedMaxBudget.amount,
          currency: estimatedMaxBudget.currency
        },
        estimatedMinBudget: {
          amount: estimatedMinBudget.amount,
          currency: estimatedMinBudget.currency
        },
      };

      const enquiryParams = {
        listingId: listing.id.uuid,
        tripDetails: updatedTripDetails,
      };

      // To initiate an enquiry and get transactionId
      const res = await onInitiateEnquiry(enquiryParams);

      // Once the new transaction is initiated successfully, tripDetails should be removed from localStorage.
      localStorage.removeItem('tripDetails');
      const txId = res?.id?.uuid;

      // Based on transaction initiation, should navigate into order details page
      // FIXME: the initial values should be considered again
      const initialValues = {
        listing,
      }

      const { setInitialValues } = findRouteByRouteName('OrderDetailsPage', routes);

      // Set OrderDetailsPage initial values
      callSetInitialValues(setInitialValues, initialValues)
      history.push(createResourceLocatorString('OrderDetailsPage', routes, { id: txId }))
    }
  }

  const stepBack = () => {
    if (step <= 1) {
      history.push(createListingURL(routeConfiguration(), listing))
    } else {
      setStep(step - 1);
      window.scrollTo(0, 0);
    }
  }

  const handleExternalDetailsFormSubmit = () => {
    if (detailsFormRef) {
      detailsFormRef.current.submitForm();
    }
  }

  const handleExternalTripFormSubmit = () => {
    if (tripFormRef) {
      tripFormRef.current.submitForm();
    }
  }

  const onClose = () => {
    setLoginPrompt(false);
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the customerRequirement is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CustomerRequirementPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   */

  const loadInitialData = () => {
    const {
      orderData,
      listing,
      transaction,
      history,
    } = props;

    if (localStorage.getItem('tripDetails')) {
      const tripData = JSON.parse(localStorage.getItem('tripDetails'));
      setTripDetails(tripData)
    } else {
      // Set localStorage so that page refreshes won't trigger duplicate
      localStorage.setItem('tripDetails', JSON.stringify(tripDetails))
    }

    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';

    const hasDataInProps = !!(orderData && listing && hasNavigatedThroughLink);
    if (hasDataInProps) {
      // Store data only if data is passed through props and user has navigated through a link.
      storeData(orderData, listing, transaction, STORAGE_KEY);
    }

    // NOTE: stored data can be empty if user has already successfully completed transaction.
    const pageData = hasDataInProps ? { orderData, listing } : storedData(STORAGE_KEY);
    setPageData(pageData);
    setDataLoaded(true);
  }

  const pageProps = { title, scrollingDisabled }
  const isLoading = !dataLoaded;

  const topbar = (
    <TopbarContainer
      className={css.topbar}
      mobileRootClassName={css.mobileTopbar}
      desktopClassName={css.desktopClassName}
      currentPage={`CustomerRequirementPage`}
    />
  )

  if (isLoading) {
    return <Page {...pageProps}>{topbar}</Page>
  }

  return (
    <Page {...pageProps} className={css.pageContainer}>
      <TopbarContainer
        className={css.topbar}
        mobileRootClassName={css.mobileTopbar}
        desktopClassName={css.desktopTopbar}
      />
      <div className={css.steps}>
        <div className={css.stepContainer}>
          <div className={cns(css.mdstepperhorizontal, css.activeColor)}>
            <div
              className={cns(
                css.mdstep,
                (step === 1 || step > 1) && css.active,
                step > 1 && css.done
              )}
              onClick={() => {
                setStep(1);
              }}
            >
              <div className={css.mdstepcircle}>{step > 1 ? '✓' : 1}</div>
              <div className={css.check}></div>
              <div className={css.mdsteptitle}>Trip</div>
              <div className={css.mdstepbarleft}></div>
              <div className={css.mdstepbarright}></div>
            </div>
            <div
              className={cns(
                css.mdstep,
                (step === 2 || step > 2) && css.active,
                step > 2 && css.done
              )}
              onClick={() => {
                if (step === 1) {
                  handleExternalTripFormSubmit();
                } else {
                  setStep(2);
                }
              }}
            >
              <div className={css.mdstepcircle}>{step > 2 ? '✓' : 2}</div>
              <div className={css.check}></div>
              <div className={css.mdsteptitle}>Details</div>
              <div className={css.mdstepbarleft}></div>
              <div className={css.mdstepbarright}></div>
            </div>
          </div>
        </div>
      </div>
      <div className={css.contentContainer}>
        <TripRequirementForm
          formStep={step}
          detailsFormRef={detailsFormRef}
          tripFormRef={tripFormRef}
          initialFormValues={{ ...initialFormValues, ...tripDetails }}
          intl={intl}
          listing={listing}
          orderData={orderData}
          currencyConversionRate={currencyConversionRate}
          onChange={onChange}
          onSubmit={onSubmit}
          onBack={stepBack}
          onOverLimit={onOverLimit}
        />

        <div className={css.detailsContainerDesktop}>
          <div className={css.detailsAspectWrapper}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              alt={listingTitle}
              image={featureImage}
              variants={['landscape-crop', 'landscape-crop2x']}
            />
          </div>
          <div className={css.ContainerLine}></div>
          <div className={css.Summary}>
            <div className={css.SummaryHead}>
              <div className={css.detailsHeadings}>
                <h2 className={css.detailsTitle}>
                  <UserDisplayName user={currentAuthor} intl={intl} />
                </h2>
                <p className={css.detailsSubtitle}>{detailsSubTitle}</p>
              </div>
            </div>

            <div>
              <TripSummary
                intl={intl}
                packageData={(orderData && orderData?.packageData) || {}}
                listing={listing}
                {...tripDetails}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        id="LoginPromptModal"
        isOpen={loginPrompt}
        onClose={onClose}
        containerClassName={css.modalContainerClass}
        contentClassName={css.contentContainerClass}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalImageContainer}>
          <img src={modalImage} />
        </div>
        <div className={css.mainContainer}>
          <div className={css.logoContainer}>
            <img src={lyfshortLogo} />
          </div>
          <p className={css.modalTextClass}>{loginPromptModalMessage}</p>
          <div className={css.buttonWrapper}>
            <Button onClick={onSubmit}>Next</Button>
          </div>
        </div>
      </Modal>
    </Page>
  )
}

const mapStateToProps = (state) => {
  const { currencyConversionRate } = state.user;
  const { listing, orderData } = state.CustomerRequirementPage;
  const { isAuthenticated } = state.Auth;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    listing,
    orderData,
    currencyConversionRate,
    isAuthenticated,
  }
}

const mapDispatchToProps = dispatch => ({
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onInitiateEnquiry: (orderParams) =>
    dispatch(initiateEnquiry(orderParams)),
})

const CustomerRequirementPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CustomerRequirementPageComponent);

CustomerRequirementPage.setInitialValues = (initialValues, saveToSessionStorage = false) => {
  const { listing, orderData } = initialValues;
  storeData(orderData, listing, null, STORAGE_KEY);

  return setInitialValues(initialValues);
}

CustomerRequirementPage.displayName = "CustomerRequirementPage";

export default CustomerRequirementPage;